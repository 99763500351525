<!--<style src="./style.css"></style>-->
<template>
  <section class="card">
    <transition name="modal" mode="in-out">
      <modal v-if="modals.show"
             :closemodal="cancelar"
             @salvar="salvar"
             :errors="this.$root.errors"
             :title="getModalTitle()">
        <template slot="body">
          <form>
            <input type="hidden" v-model="itemSelecionado[0].Id" />
            <div class="form-group">
              <label for="Descricao">Descrição</label>
              <input type="text" ref="descricao" v-focus class="form-control form-control-sm" v-model="itemSelecionado[0].Descricao" id="Nome">
            </div>
            <div class="form-group">
              <label for="Ativo">Ativo</label>
              <div class="checkbox-custom chekbox-primary">
                <input id="Ativo" value="true" type="checkbox" v-model="itemSelecionado[0].Ativo" name="Ativo" required="">
                <label for="Ativo"></label>
              </div>
            </div>
            <div class="form-group">
              <label for="Descricao">Regras de abertura <button title="Excluir tipo de requisição"
                      type="button"
                      class="novo btn btn-sm btn-default"
                      @click="handleAddRegraButton()">
                <i class="fas fa-plus" style="color: inherit;"></i>
              </button><br /><small class="text-muted">(Selecione os status dos veículos que poderão ser criados despesa neste tipo)</small></label>
              <table class="table table-sm table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Status estoque</th>
                    <th>Pós garantia</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in itemSelecionado[0].RegraStatus" :key="item.Id">
                    <td>
                      <select v-model="item.StatusEstoque"
                              class="form-control form-control-sm">
                        <option v-for="s in statusEstoque" :key="s.Id" :value="s.Id">{{s.Nome}}</option>
                      </select>
                    </td>
                    <td>
                      <div class="checkbox-custom chekbox-primary">
                        <input title="Indica se esse tipo de despesa poderá ser utilizado em veículos vendidos que já venceu a garantia" id="PosGarantia" value="true" :disabled="item.StatusEstoque !== 3" type="checkbox" v-model="item.PosGarantia" name="PosGarantia">
                        <label for="PosGarantia"></label>
                      </div>
                    </td>
                    <td>
                      <button title="Excluir tipo de requisição"
                              type="button"
                              class="novo btn btn-sm btn-danger"
                              @click="removerRegra(item)">
                        <i class="fas fa-trash" style="color: inherit;"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>

        </template>
        <template v-slot:footer="slotFooterProps">
          <button class="btn btn-sm pull-right btn-primary modal-confirm"
                  @click="slotFooterProps.clickHandler('salvar')">
            Salvar
          </button>
        </template>
      </modal>
    </transition>
    <data-header :handleTrocarVisao="handleTrocarVisao"
                 :filtros="filtros"
                 :itemSelecionado="itemSelecionado"
                 :actionButtons="actionButtons">
      <template slot="header">
        <span class="selecionado" v-html="selecionado">
        </span>
      </template>
    </data-header>
    <div class="row main-row">
      <filtro-component :filtros="filtros"
                        :params="params"
                        :handleConsultar="handleConsultar">
      </filtro-component>
      <data-table :result="result"
                  :params="params"
                  :handleRowClick="handleRowClick"
                  :checkboxClass="resolveCheckboxClass"
                  :itemSelecionado="itemSelecionado[0]"
                  :resolveStatusClass="resolveStatusClass"
                  :handleOrdenar="handleOrdenar"
                  :selected="selected"
                  :filtros="filtros"
                  :handleConsultar="handleConsultar">
      </data-table>
    </div>
  </section>
</template>
<!--<script>
...
</script>-->

<script>
  import consultar from '@/services/consultar.js';
  import api from '@/services/api.js';
  export default {
    name: 'TiposDespesa',
    components: {
      ...consultar.components,
    },
    data() {
      const that = this;
      return {
        ...consultar.data(),
        url: '/parametros/tiposdespesa',
        clone: '',
        obj: {},
        actionButtons: [
          {
            text: 'Editar',
            className: 'editar padrao',
            icon: 'fas fa-edit',
            color: 'default',
            visible: true,// inicial
            visibleOnRowSelected: true,
            title: 'Editar item selecionado',
            callback: that.handleEditarButton
          },
          {
            text: '',
            className: 'novo padrao',
            icon: 'fas fa-plus',
            color: 'default',
            visible: true,
            title: 'Incluir novo tipo de despesa',
            callback: that.handleNovoButton
          },
          {
            text: '',
            className: 'excel padrao',
            icon: 'fas fa-file-excel',
            iconColor: 'green',
            color: 'default',
            visible: true,
            title: 'Exportar para excel',
            callback: that.exportar
          },
        ],
        filtros:
        {
          data: [
            {
              id: 'Nome',
              title: 'Descrição',
              active: false,
              placeholder: '',
            },
            {
              id: 'Ativo',
              title: 'Status',
              active: false,
              value: 'true',
              source: [
                {
                  id: 'Ativo',
                  title: 'Ativo',
                  value: 'true'
                },
                {
                  id: 'Ativo',
                  title: 'Inativo',
                  value: 'false'
                }
              ],
              sourceType: 'radio'
            }
          ],
          visao: [
            {
              id: 0,
              title: 'Todos tipos de despesas',
              filtro: []
            },
            {
              id: 1,
              title: 'Tipos de despesas Ativas',
              filtro:
                [
                  {
                    id: 'Ativo',
                    value: true,
                  },
                ]
            }
          ],
          visaoSelecionada: 0,
          visible: true
        },
        statusEstoque: []
      };
    },

    methods: {
      ...consultar.methods,
      removerRegra(item) {
        let arr = this.itemSelecionado[0].RegraStatus;
        arr.splice(arr.indexOf(item), 1);
        this.itemSelecionado[0].RegraStatus = arr;

      },
      callback(response) {
        this.statusEstoque = response.data.More;
      },
      regraStatusEmpty(tipoRequisicaoId) {
        let tipoEmpty = {
          TipoRequisicaoId: tipoRequisicaoId,
          StatusEstoque: null,
          PosGarantia: false,
        };

        return tipoEmpty;
      },
      handleNovoButton: function () {
            this.$root.errors = [];
            this.selected.pop();
            this.itemSelecionado.pop();
            this.itemSelecionado.push({ Id: 0, Ativo: true, Descricao: '', RegraStatus: [],  });

            this.modals.show = true;
      },
      handleAddRegraButton() {
        this.itemSelecionado[0].RegraStatus.push(this.regraStatusEmpty(this.itemSelecionado[0].Id));
      },
      salvar: function () {
        this.$root.errors = [];
        debugger;
        if (this.itemSelecionado[0].RegraStatus.some(r=>typeof(r.StatusEstoque) === 'object')) {
          this.$root.errors.push('Selecione o status da regra adicionada.');
          return;
        }
        api
            .post(this.url, this.itemSelecionado[0])
            .then(response => {
                console.log(response.data);
                if (response.data.success) {

                    //busco id do item que retornou no array
                    let x = this.result.Records.filter(i => i.Id === response.data.obj.Id);
                    //se encontreim substituo o item
                    if (x.length) {
                        this.result.Records.splice(this.result.Records.indexOf(x[0]), 1, response.data.obj);
                    }
                    //se não encontrei, insiro ele na primeira posição
                    else {
                        this.result.Records.splice(0, 0, response.data.obj);
                    }
                    this.modals.show = false;
                    this.itemSelecionado.pop();
                    this.selected.pop();
                    this.changeCheckboxCallback();
                }
                else {
                    this.$root.errors = response.data.errors;
                }
            });

        },
    },
    mounted: consultar.mounted,

    beforeMount: function () {

    }
  };
</script>
